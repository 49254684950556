import React, { useState, useEffect} from 'react'
import { Route } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { LinkContainer } from 'react-router-bootstrap'
import { Navbar, Nav, Container, NavDropdown, ButtonGroup, Dropdown, DropdownButton, Button } from 'react-bootstrap'
import SearchBox from './SearchBox'
import { logout } from '../actions/userActions'
import { bgBlack } from 'colors'

const Header = () => {
  const dispatch = useDispatch()
  const [isButtonGroupVisible, setButtonGroupVisible] = useState(window.innerWidth >= 768);


  const userLogin = useSelector((state) => state.userLogin)
  const { userInfo } = userLogin

  const logoutHandler = () => {
    dispatch(logout())
  }

  useEffect(() => {
    const handleResize = () => {
      setButtonGroupVisible(window.innerWidth >= 768);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <header className='sticky-top'>
      <Navbar  className='custom-navbar' bg='primary' variant='dark' expand='lg' collapseOnSelect vertical>
        <Container style={{marginTop: -9, width: 100+'%' }} className='bg-primary'>
          <LinkContainer  className='' to='/'>
            <Navbar.Brand>THE LOTUSTOR</Navbar.Brand>
          </LinkContainer>
          <Navbar.Toggle style={{marginTop: -9}} aria-controls='basic-navbar-nav' />
          <Navbar.Collapse id='basic-navbar-nav'>
            <Route style={{ borderWidth: 1,
    borderColor: bgBlack, borderRadius: 25 }} render={({ history }) => <SearchBox history={history} />} />
            <Nav className='ms-auto'>
              <LinkContainer to='/cart'>
                <Nav.Link>
                  <i className='fas fa-shopping-cart'></i> Cart
                </Nav.Link>
              </LinkContainer>
              {userInfo ? (
                <NavDropdown title={userInfo.firstName} id='username'>
                  <LinkContainer to='/profile'>
                    <NavDropdown.Item>Profile</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/contactsupport'>
                    <NavDropdown.Item>Contact Support</NavDropdown.Item>
                  </LinkContainer>
                  <NavDropdown.Item onClick={logoutHandler}>
                    Logout
                  </NavDropdown.Item>
                </NavDropdown>
              ) : (
                <LinkContainer to='/login'>
                  <Nav.Link>
                    <i className='fas fa-user'></i> Sign In
                  </Nav.Link>
                </LinkContainer>
              )}
              {userInfo && userInfo.isAdmin && (
                <NavDropdown title='Admin' id='adminmenu'>
                  <LinkContainer to='/admin/userlist'>
                    <NavDropdown.Item>Users</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/admin/productlist'>
                    <NavDropdown.Item>Products</NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to='/admin/orderlist'>
                    <NavDropdown.Item>Orders</NavDropdown.Item>
                  </LinkContainer>
                </NavDropdown>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>


      {/* {isButtonGroupVisible && (
        <ButtonGroup display="flex-box" variant='primary' vertical className='float-md-start'>
          <Button>Button</Button>
            <Button>Button</Button>

            <DropdownButton display="inline" as={ButtonGroup} title="Dropdown" id="bg-vertical-dropdown-1">
              <Dropdown.Item eventKey="1">Dropdown link</Dropdown.Item>
              <Dropdown.Item eventKey="2">Dropdown link</Dropdown.Item>
            </DropdownButton>

            <Button>Button</Button>
            <Button>Button</Button>
            <DropdownButton as={ButtonGroup} title="Dropdown" id="bg-vertical-dropdown-2">
              <Dropdown.Item eventKey="1">Dropdown link</Dropdown.Item>
              <Dropdown.Item eventKey="2">Dropdown link</Dropdown.Item>
            </DropdownButton>

            <DropdownButton as={ButtonGroup} title="Dropdown" id="bg-vertical-dropdown-3">
              <Dropdown.Item eventKey="1">Dropdown link</Dropdown.Item>
              <Dropdown.Item eventKey="2">Dropdown link</Dropdown.Item>
            </DropdownButton>
            </ButtonGroup>
      )} */}

    </header>

    

    
  )
}

export default Header
